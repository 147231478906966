import React, { useRef } from "react";

import SEO from "../components/seo";
import Layout from "../components/layout";
// import HomeHero from "../modules/hero/home-hero";
import ServicesHero from "../modules/hero/services-hero";
import FooterMapDetails from "../components/footer/footer-map-details";
import ModuleTitleAndDetails from "../modules/module-titleanddetails";
import ModuleImageGallery from "../modules/module-image-gallery";

const About = ({ location }) => {
  const pageLayoutRef = useRef();

  const seo = {
    pageUrl: "/about",
    pageTitle: "About | Vello | A full-service creative agency.",
    indexibility: "Indexable",
    metaDescription: "About | Vello | A full-service creative agency.",
  };

  const footerDate = {
    location: location,
    menu: [
      {
        text: "Start a project",
        link: "/start-a-project",
      },
      {
        text: "Our Services",
        link: "/services",
      },
      {
        text: "Our Work",
        link: "/work",
      },
    ],
    description: `
    We define and develop solutions carefully, skillfully, and beautifully. Powered by a solid team of talented professionals, we collaborate globally to craft the best solutions for you.`,
  };

  return (
    <div ref={pageLayoutRef}>
      <SEO data={seo} location={location} />
      <Layout location={location}>
        <ServicesHero
           title={`About.`}
           description={`As a digitally-focused agency, we’re a forward-thinking team driven by passion -- and fuelled by curiosity.`}
           logoImage={''}
           pageLayoutRef={pageLayoutRef}
        />
        <ModuleTitleAndDetails />
        <ModuleImageGallery />
        <FooterMapDetails {...footerDate} pageLayoutRef={pageLayoutRef} />
      </Layout>
    </div>
  );
};

export default About;
